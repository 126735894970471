import { defineStore } from "pinia";
import { auth, db } from "../../firebase-config";
import { Comment } from "@/typings/interface";

export const useUserStore = defineStore('user', {
    state:() => ({
        comments: null as Comment[] | null
    }),

    getters: { 
        getComments: (state) => state.comments
    },

    actions: {
        fetchComments() {
            const userId = auth.currentUser?.uid;
            if (userId) {
                try {
                    db.collection('comments').where('answer_author_id', '==', userId).orderBy('created_at', 'desc').onSnapshot((querySnapshot) => {
                        if(querySnapshot.docs) {
                            const comments: Comment[] = [];
                            querySnapshot.forEach((doc) => {
                                const comment: Comment = {
                                    id: doc.id,
                                    exercise_id: doc.data().exercise_id,
                                    question_id: doc.data().question_id,
                                    question: doc.data().question,
                                    answer_author_id: doc.data().answer_author_id,
                                    anwser: doc.data().anwser,
                                    comment: doc.data().comment,
                                    commenter_name: doc.data().commenter_name,
                                    seen: doc.data().seen
                                };
                                comments.push(comment);
                            });

                            this.comments = comments;
                        }
                    })
                } catch (errro) {
                    console.log(errro)
                }
            }
        }
    }
})