import firebase from "firebase/compat/app";
import { useToast } from 'vue-toast-notification';
import jsPDF from 'jspdf';
import { Logbook, User, SimulationVideo } from '@/typings/interface';


const toast = useToast();

export const hasCookieConsent = (): boolean => {
    const consent = localStorage.getItem('cookie-consent');
    if (consent === 'true') {
        return true;
    }
    else {
        return false;
    }
};

export const formatSecondsToTime = (seconds: number): string => {
	const h = Math.floor(seconds / 3600);
	const m = Math.floor((seconds % 3600) / 60);
	const s = (seconds % 3600) % 60;
	const formattedTime = `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
	return formattedTime;
}

export const getCurrentDate = (): string => {
	const currentDate = new Date();

	let day: string | number = currentDate.getDate();
	let month: string | number = currentDate.getMonth() + 1;
	const year: string | number = currentDate.getFullYear();

	day = (day < 10) ? '0' + day : day;
	month = (month < 10) ? '0' + month : month;

	const formattedDate = day + '/' + month + '/' + year;

	return formattedDate;
}

export const validateEmail = (email: string): boolean => {
	const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	if (reg.test(email)) {
		return true;
	} else {
		return false;
	}
}

export const showToast = (type: string, message: string) => {
	toast.open({
		message: message,
		type: type
	});
}

interface OptionObject {
	text?: string;
	video_src?: string;
	img_src?: string;
}

interface DataObject {
	actor?: string;
	message?: string;
	playVideo?: string;
	video_src?: string;
	question?: string;
	options?: Array<OptionObject | string>;
	selectedOption?: { text: string };
}

export const generateStorylogPDF = async (data: DataObject[]) => {
	const doc = new jsPDF();
	let yPosition = 10;
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const logoImg = await loadLogo(require("../assets/images/logo_tacos.png"));
	const logoBase64 = convertImageToBase64(logoImg, 0.1);

	const pageWidth = 210;
	const pageHeight = 297;
	const logoWidth = 200;
	const logoHeight = (logoImg.height / logoImg.width) * logoWidth;
	const logoX = pageWidth / 2 - logoWidth / 2;
	const logoY = pageHeight / 2 - logoHeight / 2;

	const addWatermark = () => {
		doc.addImage(logoBase64, 'PNG', logoX, logoY, logoWidth, logoHeight);
	};

	addWatermark();
	data.forEach((item) => {
		if (yPosition > 280) {
			doc.addPage();
			addWatermark();
			yPosition = 10;
		}

		if (item.message) {
			const actorName = item.actor ? item.actor.charAt(0).toUpperCase() + item.actor.slice(1) : 'Tacos';
			doc.setFontSize(14);
			doc.setFont('helvetica', 'bold');
			doc.text(actorName, 10, yPosition);
			yPosition += 7;

			doc.setFontSize(12);
			doc.setFont('helvetica', 'normal');
			const lines = doc.splitTextToSize(cleanString(item.message), 180);
			if (yPosition + (5 * lines.length) > pageHeight - 20) {
                doc.addPage();
                yPosition = 20;
            }

			doc.text(lines, 10, yPosition);
			yPosition += 5 * lines.length;
		}

		const videoLink = item.playVideo || item.video_src;
		if (videoLink) {
			doc.setFontSize(12);
			doc.setTextColor(0, 0, 255);
			doc.text('Filmpje', 10, yPosition);
			doc.setTextColor(0);
			yPosition += 7;
		}

		if (item.question) {
			yPosition += 5;
			doc.setFontSize(14);
			doc.setFont('helvetica', 'bold');
			doc.text('Vraag', 10, yPosition);
			yPosition += 7;
			doc.setFontSize(12);
			doc.setFont('helvetica', 'normal');
			const lines = doc.splitTextToSize(cleanString(item.question), 180);
			if (yPosition + (5 * lines.length) > pageHeight - 20) {
                doc.addPage();
                yPosition = 20;
            }


			doc.text(lines, 10, yPosition);
			yPosition += 5 * lines.length;
		}

		if (item.options) {
			yPosition += 5;
			doc.setFontSize(12);
			doc.setFont('helvetica', 'bold');
			doc.text('Opties', 10, yPosition);
			yPosition += 7;
			doc.setFont('helvetica', 'normal');

			item.options.forEach((option, optionIndex) => {
				let optionText = '';

				if (typeof option === 'object') {
					if (option.text) {
						optionText = `${optionIndex + 1}. ${option.text}`;
					}
					if (option.video_src) {
						doc.setTextColor(0, 0, 255);
						optionText = `${optionIndex + 1}. Filmpje`;
					}
					if (option.img_src) {
						doc.setTextColor(0, 0, 255);
						optionText = `${optionIndex + 1}. Avatar`;
					}
				}

				if (typeof option === 'string') {
					optionText = `${optionIndex + 1}. ${option}`;
				}

				const lines = doc.splitTextToSize(cleanString(optionText), 180);
				if (yPosition + (5 * lines.length) > pageHeight - 20) {
					doc.addPage();
					yPosition = 20;
				}
	
				doc.text(lines, 10, yPosition);
				yPosition += 5 * lines.length;
			});
		}

		yPosition += 10;
	});

	doc.save('overzicht.pdf');
}

export const generatelogbookPDF = (data: Logbook[]) => {
	const doc = new jsPDF();
	const pageWidth = 210;
	const pageHeight = 297;
	let yPosition = 20;
	doc.setTextColor('#231F20');
	
	doc.setFontSize(14);
	doc.setFont('helvetica', 'bold');
	const lines = doc.splitTextToSize(cleanString(data[0].simulation_core_message), 180);
	doc.text(lines, pageWidth/2, yPosition, {align: 'center'});
	yPosition += 10 * lines.length;

	data.forEach((item) => {
		if (yPosition > pageHeight - 20) {
			doc.addPage();
			yPosition = 20;
		}

		if (item.question) {
			yPosition += 5;
			doc.setFontSize(12);
			doc.setFont('helvetica', 'bold');
			const lines = doc.splitTextToSize(cleanString(item.question), 180);
			if (yPosition + (5 * lines.length) > pageHeight - 20) {
                doc.addPage();
                yPosition = 20;
            }

			doc.text(lines, 10, yPosition);
			yPosition += 5 * lines.length;
		}

		if (item.log) {
			yPosition += 5;
			doc.setFontSize(12);
			doc.setFont('helvetica', 'normal');
			const lines = doc.splitTextToSize(cleanString(item.log), 180);
			if (yPosition + (5 * lines.length) > pageHeight - 20) {
                doc.addPage();
                yPosition = 20;
            }

			doc.text(lines, 10, yPosition);
			yPosition += 5 * lines.length;
		}
	});

	doc.save('logboek.pdf');
}

export const cleanString = (string: string) => {
	let cleanedText = string.replace(/<[^>]*>/g, '');
	cleanedText = cleanedText.replace(/&#\d+;/g, '');
	cleanedText = cleanedText.replace(/\(bold\)|\(end\)/g, '');
	cleanedText = cleanedText.trim();
	return cleanedText;
}

const convertImageToBase64 = (img: HTMLImageElement, opacity?: number): string => {
	const canvas = document.createElement('canvas');
	canvas.width = img.width;
	canvas.height = img.height;
	const ctx = canvas.getContext('2d');
	if (ctx) {
		ctx.globalAlpha = opacity || 1;
		ctx.drawImage(img, 0, 0);
	}
	return canvas.toDataURL('image/png');
};

const loadLogo = (logoURL: string): Promise<HTMLImageElement> => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.src = logoURL;
		// img.crossOrigin = "Anonymous";
		img.onload = () => resolve(img);
		img.onerror = reject;
	});
};

export const getFavoriteVideos = async (user: User, db: firebase.firestore.Firestore): Promise<SimulationVideo[]> => {
	const favoriteVideos: SimulationVideo[] = [];
	if (user.favorite_videos) {
		await Promise.all(user.favorite_videos.map(async (videoId) => {
			const snapshot = await db.collectionGroup('simulation_videos').where('id', '==', videoId).get();
			if (!snapshot.empty) {
				snapshot.docs.forEach((video) => {
					favoriteVideos.push({
						id: video.id,
						simulation_id: video.data().simulation_id,
						url: video.data().url,
						total_likes: video.data().total_likes,
						liked_by: video.data().liked_by,
					});
				});
			}
		}));
	}

	return favoriteVideos;
};

export const getGlobalTop10Videos = async (db: firebase.firestore.Firestore): Promise<SimulationVideo[]> => {
	const globalTop10Videos: SimulationVideo[] = [];

	const snapshot = await db.collectionGroup('simulation_videos').orderBy('total_likes', 'desc').limit(10).get();

	if (!snapshot.empty) {
		snapshot.docs.forEach((video) => {
			globalTop10Videos.push({
				id: video.id,
				simulation_id: video.data().simulation_id,
				url: video.data().url,
				total_likes: video.data().total_likes,
				liked_by: video.data().liked_by,
			});
		});
	}
	return globalTop10Videos;
};


export const roles: string[] = ['journalist', 'superkracht', 'talkshowhost', 'taalcoach', 'taaladviseur', 'tv-kok', 'voetbalcommentator'];

export const roleInfo: Record<string, string> = {
	journalist: `<p><strong>Je gebruikt goede taal- en denkuitlokkende vragen en prompts (Taxonomie van Bloom).</strong>&nbsp;</p><p>Bv. ‘Wat ben je van plan?’&nbsp;</p><p>Bv. ‘Hoe heb je dat aangepakt?’&nbsp;</p><p>Bv. ‘Hoe <i>maak je thee</i>?’&nbsp;</p><p>Bv. ‘Wat gebeurt er als <i>er twee beren in het hol willen</i>?’&nbsp;</p><p>Bv. ‘Wat zou jij doen als <i>er een draak in je bed ligt</i>?’&nbsp;</p><p>Bv. ‘Waarom staat hier <i>‘o nee’</i>?’&nbsp;</p><p>Bv. ‘Denk je dat <i>de beer in het hol past</i>? Waarom?’&nbsp;</p><p>Bv. ‘Hoe zou jij dit probleem <i>van Beer</i> oplossen?’&nbsp;</p><p>Bv. ‘Ze wil <i>op de mier trappen!</i> Wat vind jij daarvan?’&nbsp;</p><p>Bv. ‘Wat zou er nu gebeuren?’&nbsp;</p><p>Bv. ‘Ik begrijp niet <i>wat Beer doet</i>.’&nbsp;</p><p>Bv. ‘Beschrijf eens hoe <i>een koning eruitziet</i>.’&nbsp;</p><p>Bv. ‘Ik zag deze morgen <i>een schaap op het trottoir</i>!’&nbsp;&nbsp;</p><p>Bv.’ Ik heb het zo warm, ik trek nóg een trui aan!’&nbsp;</p>`,
	superkracht: 'info over de superkracht',
	talkshowhost: `<p><strong>Je gaat op ooghoogte zitten, maakt (fysiek) contact, kijkt de kleuter vriendelijk en verwachtingsvol aan, knikt, maakt instemmende geluiden, zet de kracht van stiltes in, schenkt vertrouwen en beschermt spreek- en denkbeurten.</strong>&nbsp;</p><p>Bv. ‘Ja?’, ‘Vertel eens?’&nbsp;</p><p>Bv. ‘Ik zie Frieda denken.’&nbsp;</p><p>Bv. ‘Ik zie dat Enes nog denkt. Wacht even, Indra. Vertel maar, Enes.’&nbsp;&nbsp;</p>`,
	taalcoach: `<p><strong>Je herhaalt een onvolledige of foutieve uiting in een correcte en volledige zin (</strong><i><strong>recast</strong></i><strong>) en laat die eventueel herhalen door de kleuter.</strong>&nbsp;</p><p>Bv. ‘Hij *hebt mij *geslaan.’ &gt;&gt; ‘Oh, heeft hij jou geslagen?’&nbsp;</p><p>Bv. ‘Poes!’ &gt;&gt; ‘Ja, dat klopt, daar zit een mooi gestreepte poes.’&nbsp;</p><p>Bv. ‘Poes!’ &gt;&gt; ‘Ja, daar loopt een poes. Zeg het maar: “Daar loopt een poes!”’&nbsp;</p>`,
	taaladviseur: `<p><strong>Je gaat expliciet in op de inhoud of verwoording.</strong>&nbsp;</p><p>Bv. ‘Kijk, *trein!’ à ‘O, je bedoelt een tram. De tram lijkt op de trein: ze rijden beide op sporen. De tram rijdt in de stad. De trein rijdt van de ene stad naar de andere.’&nbsp;</p><p>Bv. ‘Ah, ik dacht dat jouw hond een vrouwtje was, omdat je zei: ‘ze is lief’. Maar je bedoelde: ‘hij is lief’.’&nbsp;</p><p>Bv. ‘Het woord Sint begint met dezelfde klank en letter als jouw naam, Sabine.’&nbsp;</p><p>Bv. ‘Ah, de <i>lavage</i>. Dat is Frans voor carwash. In het Nederlands gebruiken we een Engels woord.’&nbsp;</p>`,
	'tv-kok': `<p><strong>Je ondersteunt de kern van je eigen rijke taal met 2D- en 3D-materiaal, gebaren, tekeningen of tekst.</strong>&nbsp;</p><p>Bv. je beeldt uit wat er gebeurt in een verhaal &nbsp;</p><p>Bv. je maakt een mindmap van alle ideeën&nbsp;</p><p>Bv. je gebruikt voorwerpen ter illustratie&nbsp;</p>`,
	voetbalcommentator: `<p><strong>Je verwoordt met rijke taal wat je ziet, wat je doet (</strong><i><strong>self talk</strong></i><strong>), wat de kleuter doet (</strong><i><strong>parallel talk</strong></i><strong>), ook tijdens momenten die normaal in stilte verlopen.</strong>&nbsp;</p><p>Bv. ‘Ik neem het kaartje uit de doos en geef het kaartje aan jou.’ &nbsp;</p><p>Bv. ‘Ik neem er even mijn schrift bij om op te schrijven wie er vandaag allemaal is.’&nbsp;</p><p>Bv. ‘Je schept nu een handjevol zand in de zeef. O kijk: het fijne zand stroomt door de gaatjes. De parels en propjes blijven liggen.’&nbsp;</p>`
};