<template>
    <section>
        <div class="cowork"><img src="../assets/images/logo_notext.svg">
            <p>is een samenwerking tussen</p>
        </div>
    </section>

    <div class="footer-container">
        <div class="partners">
            <a href="https://www.arteveldehogeschool.be/" target="_blank"><img src="../assets/images/partners/artevelde.svg" /></a>
            <a href="https://www.odisee.be/" target="_blank"><img src="../assets/images/partners/odisee.svg" /></a>
            <a href="https://www.kuleuven.be/" target="_blank"><img src="../assets/images/partners/kul.svg" /></a>
            <a href="https://ugent.be/" target="_blank"><img src="../assets/images/partners/ugent.svg" /></a>
        </div>

        <div class="thanks">
            <p>met dank aan</p>
        </div>

        <div class="partners">
            <a href="https://www.fwo.be/" target="_blank"><img src="../assets/images/partners/fwo.svg" /></a>
            <a href="https://ugent.be/" target="_blank"><img src="../assets/images/partners/plantyn.svg" /></a>
            <a href="https://ugent.be/" target="_blank"><img src="../assets/images/partners/welzijnszorg.svg" /></a>
        </div>

        <section>

            <div class="cta">
                <p>
                    Wil je graag deelnemen of heb je andere vragen?
                </p>
                <router-link to="/contact">Neem contact op</router-link>
            </div>
            <div class="disclaimer">
                <p>&copy; 2023 Tacos</p>
            </div>
        </section>
    </div>
</template>

<style lang="scss" scoped>
.footer-container {
    background-color: #fff;

    border-top: 1px solid $blue;
}

.cowork {
    padding-bottom: 2rem;
    padding-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
        height: 2rem;

    }

    p {
        line-height: 2rem;
    }

    @media screen and (min-width: $breakpoint) {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;

        img {
            margin-bottom: 0.3rem;
            margin-right: 2rem;
        }

        p {
            margin: 0;
        }
    }
}

.thanks {
    margin-top: 5rem;
    text-align: center;
}

.partners {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5rem;

    @media screen and (min-width: $breakpoint) {
        padding-left: 5rem;
        padding-right: 5rem;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-evenly;

        a {
            height: auto;
            width: calc(100% / 5 - 2rem *4);

            img {
                height: auto;
                width: 100%;
            }
        }
    }

    a {
        height: 5.5rem;
        display: block;

        img {
            height: 100%;
        }

        &:nth-child(5) {
            height: 8rem;
        }

    }

}

.contact-link {
    margin-left: 0;
    margin-top: 2rem;

    @media screen and (min-width: $breakpoint) {
        margin-left: auto;
    }

    height: 4rem;
}

.disclaimer {
    margin-top: 2rem;
    font-style: italic;
    text-align: center;

    p {
        margin-bottom: 0;
        font-size: 1.4rem;
    }
}
</style>