import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Vue3TouchEvents from 'vue3-touch-events';
import CKEditor from '@ckeditor/ckeditor5-vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { createPinia } from 'pinia';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import ToastPlugin from 'vue-toast-notification';
import PrimeVue from 'primevue/config';
import 'vue-toast-notification/dist/theme-bootstrap.css';

library.add(fas);
const pinia = createPinia();


createApp(App)
.use(router)
.use(Vue3TouchEvents)
.use(CKEditor)
.use(pinia)
.use(ToastPlugin)
.use(PrimeVue)
.use(VueReCaptcha, { siteKey: '6LeSgjgpAAAAAKUxoUC4CH2GVAgf_qAmqpo433FK', loaderOptions: {} })
.component('fa-icon', FontAwesomeIcon)
.mount('#app');

